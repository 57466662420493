<template>
  <div class="error">
    <img src="./images/404.gif" />
    <p>您可能输错了网址,或该网页已删除或不存在</p>
    <a :href="homeUrl" class="btn btn-primary btn_blue">
      返回主页
    </a>
  </div>
</template>

<script>
export default {
  name: 'Error',
  components: {},
  data() {
    return {
      homeUrl: process.env.VUE_APP_BASE_URL,
    }
  },
  mounted() {},
  methods: {},
}
</script>

<style lang="scss">
.error {
  width: 100%;
  background-color: #faf9fa;
  border-radius: $def-radius;
  padding: $def-margin * 4;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.error img {
  display: block;
  margin: auto;
}

.error p {
  font-size: 14px;
  color: #666666;
  letter-spacing: 1px;
  text-align: center;
}

.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 888px;
}

.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}

.btn_blue {
  display: inline-block;
  height: 56px;
  line-height: 56px;
  text-align: center;
  border-radius: 3px;
  background: #0052d0;
  color: #fff;
  letter-spacing: 5px;
  border: 0;
  font-size: 18px;
  padding: 0;
  text-decoration: none;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-property: background;
  transition-property: background;
}

.error a {
  display: block;
  margin: auto;
  width: 220px;
  height: 56px;
}
</style>
